<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">一人一码列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="学员搜索" class="searchboxItem ci-full">
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">班级搜索:</span>
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superProjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="所属部门"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">所属部门:</span>
              <el-input
                v-model="departmentQuery"
                placeholder="请输入所属部门"
                clearable
                size="small"
              ></el-input>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handDownloadDetails()"
              >下载明细</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handDownload()"
              >下载</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
              width="100px"
            />
            <el-table-column
              label="姓名"
              align="left"
              prop="userName"
              show-overflow-tooltip
            />
            <el-table-column
              label="身份证号"
              align="left"
              prop="idcard"
              show-overflow-tooltip
            />
            <el-table-column
              label="手机号"
              align="left"
              prop="mobile"
              show-overflow-tooltip
            />
            <el-table-column
              label="所属部门"
              align="left"
              prop="department"
              show-overflow-tooltip
            />
            <el-table-column
              label="班级编码"
              align="left"
              prop="projectCode"
              show-overflow-tooltip
            />
            <el-table-column
              label="班级名称"
              align="left"
              prop="projectName"
              show-overflow-tooltip
            />
            <el-table-column label="操作" align="center" width="210px">
              <div slot-scope="scope" class="flexcc">
                <el-button
                  type="text"
                  style="padding: 0px 5px"
                  size="mini"
                  @click="handleLook(scope.row.userId)"
                  >查看</el-button
                >
                <el-button
                  type="text"
                  style="padding: 0px 5px"
                  size="mini"
                  @click="handleLookStudentQRCode(scope.row.userId)"
                  >查看二维码</el-button
                >
              </div>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="预览"
      :visible.sync="previewLoading"
      width="500px"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="查看个人信息"
      :visible.sync="personDialog"
      width="800px"
      top="2%"
      center
    >
      <div class="ovy-a">
        <el-form
          ref="baseform"
          label-width="7rem"
          class="form"
          :model="baseform"
          disabled
        >
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="baseform.userName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard" required>
            <el-input
              :disabled="disabledAuthentication == '20'"
              v-model="baseform.idcard"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex" size="small">
            <el-radio disabled v-model="baseform.sex" label="1">男</el-radio>
            <el-radio disabled v-model="baseform.sex" label="2">女</el-radio>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="baseform.mobile"
              size="small"
              style="width: 70%; float: left"
            ></el-input>
            <el-button
              size="small"
              class="bgc-bv"
              style="margin-left: 30px"
              v-if="show1"
              @click="xgmobile"
              >修改</el-button
            >
          </el-form-item>
          <el-form-item
            label="民族"
            prop="nation"
            :rules="{
              required: false,
              message: '请选择民族',
              trigger: 'change',
            }"
          >
            <el-select
              v-model="baseform.nation"
              placeholder="请选择民族"
              size="small"
              clearable
            >
              <el-option
                v-for="item in nationList"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="住址"
            prop="address"
            :rules="{
              required: false,
              message: '请输入住址',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="baseform.address"
              size="small"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="工作区域" prop="workingArea">
            <el-cascader
              clearable
              filterable
              v-model="baseform.workingArea"
              :options="areatreeList"
              :props="propsarea"
              size="small"
            />
          </el-form-item>
          <el-form-item label="工作单位" prop="workUnit">
            <el-input
              v-model="baseform.workUnit"
              size="small"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="学员类型" prop="studentType">
            <el-select
              v-model="baseform.studentType"
              placeholder="请选择学员类型"
              size="small"
            >
              <el-option
                v-for="item in studengTypeList"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="出生年月"
            prop="birthday"
            :rules="{
              required: false,
              message: '请选择出生年月日',
              trigger: 'change',
            }"
          >
            <el-date-picker
              size="small"
              v-model="baseform.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择出生年月日"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="身份证有效期"
            prop="idCardValidity"
            :rules="{
              required: false,
              message: '请输入身份证有效期',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="baseform.idCardValidity"
              size="small"
              placeholder="请输入身份证有效期"
            ></el-input>
            
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "OneEnterpriseOneFile",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      departmentQuery: "", //所属部门
      userId: "", //学员Id
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "", //班级Id
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      previewLoading: false,
      ImgSrc: "",
      personDialog: false,
      baseform: {},
      areatreeList: [],
      nationList: [], // 民族数据
      studentTypeList: [], //学员类型
      //   dialogVisible: false,
    };
  },
  created() {
    this.getNationList();
    this.getareatree();
    this.getstudengTypeList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superProjectSelect();
    },
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    getParameter() {
      let parameter = {};
      if (this.departmentQuery) {
        parameter.departmentQuery = this.departmentQuery;
      }
      if (this.userId) {
        parameter.studyUserId = this.userId;
      }
      if (this.projectId) {
        parameter.projectId = this.projectId;
      }
      return parameter;
    },
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.getParameter(),
      };
      this.doFetch({
        url: "/eighteenEnterprise/userCode/pageList",
        params,
        pageNum,
      });
    },
    /* 下载明细 */
    handDownloadDetails() {
      this.$post(
        "/eighteenEnterprise/userCode/list/download",
        this.getParameter()
      ).then((ret) => {
        if (ret.status == 0) {
          window.open(ret.data);
        }
      });
    },
    handDownload() {
      this.$post(
        "/eighteenEnterprise/userCode/download",
        this.getParameter()
      ).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          for (let item of list) {
            console.log(item);
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //查看二维码
    handleLookStudentQRCode(studyUserId) {
      this.$post("/eighteenEnterprise/userCode/qrcode", { studyUserId }).then(
        (res) => {
          if (res.status == "0") {
            this.previewLoading = true;
            this.ImgSrc = res.message;
          }
        }
      );
    },
    handleLook(userId) {
      this.personDialog = true;
      this.queryData(userId);
    },
    queryData(userId) {
      this.$post("/biz/user/sensitiveUserInfo", { userId })
        .then((res) => {
          const data = res.data || {};
          ;
          this.baseform = {
            ...this.baseform,
            mobile: data.mobile, // 手机号
            email: data.email, // 邮件
            idcard: data.idcard || "", // 身份证
            userName: data.userName, // 用户名
            educationLevel: data.educationLevel, // 文化水平
            authentication: data.authentication, // 认证状态
            sex: data.sex, // 性别
            headPathUrl: data.headPathUrl, //图片
            headPath: data.headPath,
            compId: data.compId,
            idPhoto: data.idPhoto, // 证件照url
            idPhotoKey: data.idPhotoKey, // 证件照url
            nation: data.nation, //民族
            address: data.address,
            workUnit: data.workUnit,
            studentType: data.studentType ? data.studentType.toString() : "",
            workingArea: data.workingArea,
            idCardValidity: data.idCardValidity,
            birthday: data.birthday,
            idCardFront: data.idCardFrontUrl,
            idCardBack: data.idCardBackUrl,
            idCardFrontPath: data.idCardFront,
            idCardBackPath: data.idCardBack,
            department: data.department,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /* 民族 */
    getNationList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "NATION",
      })
        .then((res) => {
          if (res.status == "0") {
            this.nationList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 学员类型 */
    getstudengTypeList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "STUDENTTYPE",
      })
        .then((res) => {
          if (res.status == "0") {
            this.studengTypeList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
